import { CustomerInfo } from "@hooks/useCustomerInfo";
import { formatPhoneNumber } from "@utils/formatters";

const DEFAULT_ZIPCODE = "78701";

export const getPrimaryEnrollmentId = (customerInfo: CustomerInfo) => {
  if (!customerInfo || !Array.isArray(customerInfo.lines)) {
    console.log("getPrimaryEnrollmentId: customerInfo is not undefined");
    return undefined;
  }

  const primaryLine = customerInfo.lines.find((line) => line.is_primary_enrollment);
  return primaryLine?.enrollment_id ?? customerInfo.lines?.[0]?.enrollment_id;
};

export const getCustomerZipcode = (customerInfo: CustomerInfo) => {
  if (!customerInfo || !Array.isArray(customerInfo.lines)) {
    return DEFAULT_ZIPCODE;
  }

  const line = customerInfo.lines.find((line) => line.zip_code);
  return line?.zip_code ?? DEFAULT_ZIPCODE;
};

export const getFirstInactiveLineWithPortNumber = (data: CustomerInfo) => {
  const line = data.lines.find(
    (line) => !line.activated && line.status === "Inactive" && line.port_number,
  );

  return line ? formatPhoneNumber(line.port_number) : null;
};
